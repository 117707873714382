import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { SiteMetaContext } from '../site-meta';
import { SeoFragmentFragment } from '../../../types/gatsby-types';
import { selectCanonicalBaseUrl } from '../../helpers/site-helpers';

export const SeoFragment = graphql`
  fragment SeoFragment on Nmbl_SeoPart {
    description
    image {
      ...ImageFragment
    }
    keywords
    title
  }
`;

export type SeoProps = Partial<SeoFragmentFragment> & {
  path: string;
};

export const Seo: React.FC<SeoProps> = props => {
  const siteMeta = React.useContext(SiteMetaContext);
  const canonicalUrl = `${selectCanonicalBaseUrl()}${props.path}`;
  const fallbackSeo = siteMeta?.fallbackSeo;
  const title = props.title ?? fallbackSeo?.title;
  const description = props.description ?? fallbackSeo?.description;
  const keywords = props.keywords ?? fallbackSeo?.keywords;
  const image = props.image ?? fallbackSeo?.image;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image?.src?.double?.fluid?.src && (
        <meta
          property="og:image"
          content={`${selectCanonicalBaseUrl()}${image.src.double.fluid.src}`}
        />
      )}
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="fb:app_id" content={siteMeta?.fbAppId} />
    </Helmet>
  );
};
