import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid } from '../../abstracts/theme.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import { color } from '../../abstracts/variables/colors.styles';
import { animation } from '../../abstracts/animation.styles';

export const StyledWorkCard = styled.div`
  overflow: hidden;

  &:hover {
    .work-card__image {
      opacity: 0.05;
      transform: scale(1.1);
      transition: opacity ${animation.hover}, transform ${animation.imageHover},
        filter ${animation.hover};

      @supports (-webkit-filter: grayscale(100%)) {
        filter: grayscale(100%) contrast(200%);
      }
    }
  }

  .work-card__link {
    position: relative;
    display: block;
  }

  .work-card__title {
    position: absolute;
    left: ${grid.offset.mobile};
    bottom: 8px;
    width: calc(100% - (${grid.offset.mobile} * 2));
    z-index: 1;
    color: ${color.white};
    font-family: ${font.primaryBold};

    @media ${media.tabletLandscape} {
      left: 48px;
      bottom: 18px;
      width: calc(100% - (${grid.offset.desktop} * 2));
    }
  }

  .work-card__image {
    opacity: 1;
    transition: opacity ${animation.hover}, transform ${animation.imageHoverOut};

    @supports (-webkit-filter: grayscale(100%)) {
      filter: grayscale(0%) contrast(100%);
      transition: opacity ${animation.hover},
        transform ${animation.imageHoverOut}, filter ${animation.hover};
    }

    &--mobile {
      @media ${media.tabletLandscape} {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media ${media.tabletLandscape} {
        display: block;
      }
    }
  }

  @-moz-document url-prefix() {
    &:hover {
      .work-card__image {
        filter: none;
        transition: opacity ${animation.hover},
          transform ${animation.imageHover};
      }
    }

    .work-card__image {
      filter: none;
      transition: opacity ${animation.hover},
        transform ${animation.imageHoverOut};
    }
  }
`;
