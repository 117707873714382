import {
  WorkListQueryQuery,
  PageFragmentFragment,
} from '../../../types/gatsby-types';
import React from 'react';
import { IWorkListProps } from './work-list';

export type IWorkListContext = WorkListQueryQuery &
  IWorkListProps & {
    page: PageFragmentFragment;
  };

export const WorkListContext = React.createContext<IWorkListContext | null>(
  null
);
