import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';

export const StyledVideoWidget = styled.div`
  font-size: 0;

  video {
    max-width: 100%;
  }

  .video-wrapper--desktop {
    display: none;

    @media ${media.tabletLandscape} {
      display: block;
    }
  }

  .video-wrapper--mobile {
    @media ${media.tabletLandscape} {
      display: none;
    }
  }
`;
