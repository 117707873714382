import React from 'react';
import { Image } from '../parts/image';
import { StyledVideoWidget } from './video-widget.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';
import { WidgetFragment_Nmbl_VideoWidget_Fragment } from '../../../types/gatsby-types';
import { WidgetWithColumnsProps } from './columns';
import classNames from 'classnames';

export const VideoFragment = graphql`
  fragment VideoFragment on Nmbl_MediaField {
    urls
    src {
      publicURL
    }
  }
`;

export const VideoWidgetFragment = graphql`
  fragment VideoWidgetFragment on Nmbl_VideoWidget {
    desktopVideo {
      video {
        ...VideoFragment
      }
      ratio
      fallbackImage {
        ...ImageFragment
      }
    }
    mobileVideo {
      video {
        ...VideoFragment
      }
      ratio
      fallbackImage {
        ...ImageFragment
      }
    }
    theme {
      ...ThemeFragment
    }
    columnSpan {
      ...ColumnSpanPartFragment
    }
  }
`;

export const VideoWidget: React.FC<WidgetWithColumnsProps<
  WidgetFragment_Nmbl_VideoWidget_Fragment
>> = props => {
  const desktopVideo = props.desktopVideo?.video?.src?.publicURL
    ? props.desktopVideo
    : props.mobileVideo;
  const mobileVideo = props.mobileVideo?.video?.src?.publicURL
    ? props.mobileVideo
    : props.desktopVideo;
  const [, desktopPaddingBottom] = props.paddingBottoms ?? [];

  return (
    <StyledVideoWidget
      className={classNames(
        'video-widget',
        props.columns && `columns--${props.columns}`
      )}
    >
      <StyledInViewElement>
        {desktopVideo && (
          <div
            className="video-wrapper video-wrapper--desktop"
            style={
              desktopPaddingBottom
                ? {
                    paddingBottom: `${100 * desktopPaddingBottom}%`,
                  }
                : undefined
            }
          >
            <video
              className="video-widget__video video-widget__video--desktop"
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src={desktopVideo.video?.src?.publicURL}
                type="video/mp4"
              />
              {desktopVideo.fallbackImage?.src && (
                <Image
                  className="video-widget__image video-widget__image--desktop"
                  image={desktopVideo.fallbackImage}
                  ratio={desktopVideo.ratio!}
                />
              )}
            </video>
          </div>
        )}
        {mobileVideo && (
          <div className="video-wrapper video-wrapper--mobile">
            <video
              className="video-widget__video video-widget__video--mobile"
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src={mobileVideo.video?.src?.publicURL}
                type="video/mp4"
              />
              {mobileVideo.fallbackImage?.src && (
                <Image
                  className="video-widget__image video-widget__image--mobile"
                  image={mobileVideo.fallbackImage}
                  ratio={mobileVideo.ratio!}
                />
              )}
            </video>
          </div>
        )}
      </StyledInViewElement>
    </StyledVideoWidget>
  );
};
