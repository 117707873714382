import React from 'react';
import { Image } from '../parts/image';
import { formatWithLeadingZeroes } from '../../helpers/numberHelpers';
import { WidgetFragment_Nmbl_ImageSliderWidget_Fragment } from '../../../types/gatsby-types';
import { CmsContent } from '../parts/cms-content';
import { StyledImageSlider } from './image-slider.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import ArrowSlider from '../../svg/arrowSlider.svg';
import { useCarousel } from '../carousel/useCarousel';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';

export const ImageSliderWidgetFragment = graphql`
  fragment ImageSliderWidgetFragment on Nmbl_ImageSliderWidget {
    theme {
      ...ThemeFragment
    }
    htmlBody {
      ...HtmlBodyFragment
    }
    bag {
      contentItems {
        contentItemId
        contentType
        ... on Nmbl_ImageSlide {
          image {
            alt
            caption
            image {
              ...ImageFragment
            }
          }
          mobileImage {
            alt
            caption
            image {
              ...ImageFragment
            }
          }
        }
      }
    }
  }
`;

/**
 * Note: We should use a Slider Component for this
 */
export const ImageSliderWidget: React.FC<WidgetFragment_Nmbl_ImageSliderWidget_Fragment> = props => {
  const ref = React.useRef<HTMLUListElement>(null);
  const slides = props.bag?.contentItems ?? [];

  const [
    { activeSlide, prevSlideIndices, nextSlideIndices },
    handlers,
    carouselStyles,
  ] = useCarousel(slides.length, {
    ...props,
    ref,
  });

  // whenever any of these images starts loading, let's load them all
  const [areImagesLoading, setAreImagesLoading] = React.useState(false);
  const onStartLoadingImages = React.useCallback(
    () => setAreImagesLoading(true),
    []
  );

  return (
    <StyledWrapper
      as={StyledImageSlider}
      mobilePadding="0px"
      className="image-slider"
    >
      <StyledInViewElement>
        <div className="image-slider__slider">
          <ul
            className="image-slider__slides"
            style={carouselStyles}
            ref={ref}
            onMouseDown={handlers.onMouseDown}
          >
            {/* Need to duplicate the number of visible slides before and after the slider */}
            {prevSlideIndices.map(index => {
              const slide = slides[index];

              return slide?.__typename === 'Nmbl_ImageSlide' &&
                slide.image?.image ? (
                <li key={`prev${index}`}>
                  <Image
                    ratio="double"
                    image={slide.image.image}
                    alt={slide.image.alt}
                    caption={slide.image.caption}
                    onStartLoad={onStartLoadingImages}
                    loading={areImagesLoading ? 'eager' : 'lazy'}
                  />
                </li>
              ) : null;
            })}
            {slides.map((slide, index) =>
              slide?.__typename === 'Nmbl_ImageSlide' && slide.image?.image ? (
                <li key={index}>
                  <Image
                    ratio="double"
                    image={slide.image.image}
                    alt={slide.image.alt}
                    caption={slide.image.caption}
                    onStartLoad={onStartLoadingImages}
                    loading={areImagesLoading ? 'eager' : 'lazy'}
                  />
                </li>
              ) : null
            )}
            {/* Need to duplicate the number of visible slides before and after the slider */}
            {nextSlideIndices.map(index => {
              const slide = slides[index];

              return slide?.__typename === 'Nmbl_ImageSlide' &&
                slide.image?.image ? (
                <li key={`prev${index}`}>
                  <Image
                    ratio="double"
                    image={slide.image.image}
                    alt={slide.image.alt}
                    caption={slide.image.caption}
                    onStartLoad={onStartLoadingImages}
                    loading={areImagesLoading ? 'eager' : 'lazy'}
                  />
                </li>
              ) : null;
            })}
          </ul>
          <nav className="image-slider__arrows">
            <button
              onClick={handlers.prev}
              className="image-slider__arrow image-slider__arrow--left"
            >
              <ArrowSlider />
            </button>
            <button
              onClick={handlers.next}
              className="image-slider__arrow image-slider__arrow--right"
            >
              <ArrowSlider />
            </button>
          </nav>
        </div>
      </StyledInViewElement>
      <div className="image-slider__content">
        <nav className="image-slider__pagination">
          {formatWithLeadingZeroes(activeSlide + 1)} &ndash;{' '}
          {formatWithLeadingZeroes(props.bag?.contentItems?.length ?? 0)}
        </nav>
        <StyledInViewElement className="image-slider__html">
          <CmsContent
            className="image-slider__cms-content"
            html={props.htmlBody?.html}
          />
        </StyledInViewElement>
      </div>
    </StyledWrapper>
  );
};
