import React from 'react';
import { MarqueeWidget } from './widgets/marquee';
import { RecentWorkWidget } from './widgets/recent-work';
import { ServicesWidget } from './widgets/services';
import { ImageWidget } from './widgets/image-widget';
import { VideoWidget } from './widgets/video-widget';
import { CareersWidget } from './widgets/careers-widget';
import { WorkGridWidget } from './widgets/work-grid';
import { EditorWidget } from './widgets/editor';
import { StatsWidget } from './widgets/stats';
import { BlockquoteWidget } from './widgets/blockquote';
import { ImageSliderWidget } from './widgets/image-slider';
import { TeamWidget } from './widgets/team';
import { graphql } from 'gatsby';
import { WidgetFragment_Nmbl_ColumnsWidget_Fragment } from '../../types/gatsby-types';
import { filterNotEmpty } from '../helpers/arrayHelpers';
import { ColumnsWidget } from './widgets/columns';
import classNames from 'classnames';
import { StyledWidgets } from './widgets.style';
import { InViewContainer } from './in-view/in-view-container';
import { ThemeProvider } from 'styled-components';
import { ThemePart } from '../../gatsby-node';
import { WidgetGroup } from './widget-group';
import {
  ThemeName,
  getThemeOrDefault,
  selectTheme,
} from '../helpers/theme-helpers';
import {
  NextProjectWidget,
  NextProjectWidgetProps,
} from './widgets/next-project';

export interface IWidget<TWidgetType extends string> {
  contentType: TWidgetType;
}

export type WidgetProps<
  TWidgetType extends string,
  TComponent extends React.ComponentType<any>
> = IWidget<TWidgetType> & React.ComponentPropsWithoutRef<TComponent>;

export type WidgetDefinition =
  | WidgetProps<'MarqueeWidget', typeof MarqueeWidget>
  | WidgetProps<'RecentWorkWidget', typeof RecentWorkWidget>
  | WidgetProps<'ServicesWidget', typeof ServicesWidget>
  | WidgetProps<'ImageWidget', typeof ImageWidget>
  | WidgetProps<'VideoWidget', typeof VideoWidget>
  | WidgetProps<'CareersWidget', typeof CareersWidget>
  | WidgetProps<'WorkGridWidget', typeof WorkGridWidget>
  | WidgetProps<'EditorWidget', typeof EditorWidget>
  | WidgetProps<'ColumnsWidget', typeof ColumnsWidget>
  | WidgetProps<'StatsWidget', typeof StatsWidget>
  | WidgetProps<'ImageSliderWidget', typeof ImageSliderWidget>
  | WidgetProps<'TeamWidget', typeof TeamWidget>
  | WidgetProps<'BlockquoteWidget', typeof BlockquoteWidget>
  | WidgetProps<'NextProjectWidget', typeof NextProjectWidget>;

export const Widget: React.FC<WidgetDefinition> = props => {
  switch (props.contentType) {
    case 'MarqueeWidget':
      return <MarqueeWidget {...props} />;
    case 'RecentWorkWidget':
      return <RecentWorkWidget {...props} />;
    case 'ServicesWidget':
      return <ServicesWidget {...props} />;
    case 'ImageWidget':
      return <ImageWidget {...props} />;
    case 'VideoWidget':
      return <VideoWidget {...props} />;
    case 'CareersWidget':
      return <CareersWidget {...props} />;
    case 'WorkGridWidget':
      return <WorkGridWidget {...props} />;
    case 'StatsWidget':
      return <StatsWidget {...props} />;
    case 'EditorWidget':
      return <EditorWidget {...props} />;
    case 'ColumnsWidget':
      return <ColumnsWidget {...props} />;
    case 'BlockquoteWidget':
      return <BlockquoteWidget {...props} />;
    case 'ImageSliderWidget':
      return <ImageSliderWidget {...props} />;
    case 'TeamWidget':
      return <TeamWidget {...props} />;
    case 'NextProjectWidget':
      return <NextProjectWidget {...props} />;
  }

  return null;
};

export interface WidgetsProps {
  widgets:
    | Readonly<(WidgetContentItem | NextProjectWidgetProps | undefined)[]>
    | undefined;
  themeAfter?: ThemeName;
}

export type WidgetContentItem =
  | NonNullable<
      Required<
        NonNullable<
          WidgetFragment_Nmbl_ColumnsWidget_Fragment['bag']
        >['contentItems']
      >
    >[number]
  | NextProjectWidgetProps;

const isThemedWidget = (
  widget: WidgetContentItem | undefined
): widget is WidgetContentItem & { theme: ThemePart } => {
  return !!widget && !!(widget as any).theme;
};

export const selectThemeFromContentItem = (
  widget: WidgetContentItem | undefined
) => {
  let theme: ThemeName | undefined;

  if (isThemedWidget(widget)) {
    theme = getThemeOrDefault(widget.theme.theme, 'light');
  } else if (widget) {
    switch (widget.__typename) {
      case 'Nmbl_RecentWorkWidget':
        theme = 'light';
        break;
      case 'Nmbl_WorkGridWidget':
        theme = 'light';
        break;
    }
  }

  if (widget && !theme) {
    console.warn("Couldn't determine theme for widget: ", widget);
  }

  return theme;
};

export const selectThemeOrDefaultFromContentItem = (
  widget: WidgetContentItem | undefined
) => selectThemeFromContentItem(widget) ?? 'light';

export interface IThemedWidgetGroup {
  theme: ThemeName;
  widgets: WidgetContentItem[];
}

export const Widgets: React.FC<WidgetsProps> = props => {
  const widgetGroups = React.useMemo(
    () =>
      props.widgets
        ?.filter(filterNotEmpty)
        .reduce<IThemedWidgetGroup[]>((groups, widget) => {
          const widgetTheme = selectThemeOrDefaultFromContentItem(widget);
          const currentGroup = groups[groups.length - 1];

          if (currentGroup?.theme !== widgetTheme) {
            groups.push({
              theme: widgetTheme,
              widgets: [widget],
            });
          } else {
            currentGroup.widgets.push(widget);
          }

          return groups;
        }, []) ?? [],
    [props.widgets]
  );
  return (
    <InViewContainer>
      <StyledWidgets className="widgets">
        {widgetGroups.map((group, index) => {
          return (
            <ThemeProvider theme={selectTheme(group.theme)} key={index}>
              <WidgetGroup>
                {group.widgets.map((widget, index) => (
                  <div
                    className={classNames(
                      'widget',
                      `widget--${widget.contentType}`,
                      index === 0 && 'theme-transition-before',
                      index === group.widgets.length - 1 &&
                        (!props.themeAfter ||
                          props.themeAfter !== group.theme) &&
                        'theme-transition-after'
                    )}
                  >
                    <Widget {...(widget as any)} />
                  </div>
                ))}
              </WidgetGroup>
            </ThemeProvider>
          );
        })}
      </StyledWidgets>
    </InViewContainer>
  );
};

export const HtmlBodyFragment = graphql`
  fragment HtmlBodyFragment on Nmbl_HtmlBodyPart {
    html
  }
`;
export const ThemeFragment = graphql`
  fragment ThemeFragment on Nmbl_ThemePart {
    theme
  }
`;

export const WidgetFragment = graphql`
  fragment WidgetFragment on Nmbl_ContentItem {
    contentItemId
    contentType
    ... on Nmbl_BlockquoteWidget {
      ...BlockquoteWidgetFragment
    }
    ... on Nmbl_CareersWidget {
      ...CareersWidgetFragment
    }
    ... on Nmbl_ColumnsWidget {
      ...ColumnsWidgetFragment
    }
    ... on Nmbl_EditorWidget {
      ...EditorWidgetFragment
    }
    ... on Nmbl_ImageSliderWidget {
      ...ImageSliderWidgetFragment
    }
    ... on Nmbl_ImageWidget {
      ...ImageWidgetFragment
    }
    ... on Nmbl_MarqueeWidget {
      ...MarqueeWidgetFragment
    }
    ... on Nmbl_RecentWorkWidget {
      ...RecentWorkWidgetFragment
    }
    ... on Nmbl_ServicesWidget {
      ...ServicesWidgetFragment
    }
    ... on Nmbl_StatsWidget {
      ...StatsWidgetFragment
    }
    ... on Nmbl_TeamWidget {
      ...TeamWidgetFragment
    }
    ... on Nmbl_VideoWidget {
      ...VideoWidgetFragment
    }
  }
`;
