import React from 'react';
import { WidgetFragment_Nmbl_BlockquoteWidget_Fragment } from '../../../types/gatsby-types';
import { Blockquote, BlockquoteWrapper } from './blockquote.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';

export const BlockquoteWidgetFragment = graphql`
  fragment BlockquoteWidgetFragment on Nmbl_BlockquoteWidget {
    quote
    attribution
    theme {
      ...ThemeFragment
    }
  }
`;

export const BlockquoteWidget: React.FC<WidgetFragment_Nmbl_BlockquoteWidget_Fragment> = props => (
  <StyledWrapper as={BlockquoteWrapper}>
    <StyledInViewElement>
      <Blockquote>
        <blockquote className="blockquote__text">{props.quote}</blockquote>
        {props.attribution && (
          <figcaption className="blockquote__attribution">
            {props.attribution}
          </figcaption>
        )}
      </Blockquote>
    </StyledInViewElement>
  </StyledWrapper>
);
