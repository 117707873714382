import React from 'react';
import { ThemeFragmentFragment } from '../../../types/gatsby-types';
import { StyledLink } from '../navigation/link.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { NextProject } from '../templates/work';
import { StyledNextProjectWidget } from './next-project.styles';

export interface NextProjectWidgetProps {
  contentType: 'NextProjectWidget';
  __typename: 'Nmbl_NextProjectWidget';
  next: NextProject;
  theme: ThemeFragmentFragment;
}

export const NextProjectWidget: React.FC<NextProjectWidgetProps> = props => (
  <StyledWrapper as={StyledNextProjectWidget} className="work__next-project">
    <StyledLink to={props.next.url}>
      <span>Next Project</span>
      <h3 className="work__next-project-title">{props.next.title}</h3>
    </StyledLink>
  </StyledWrapper>
);
