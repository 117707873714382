import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';
import { smallParagraphTextStyles } from '../../abstracts/typography.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const Blockquote = styled.div`
  @media ${media.tabletLandscape} {
    ${grid12}
  }

  @media ${media.ieTabletLandscape} {
    max-width: 800px;
  }

  .blockquote__text,
  .blockquote__attribution {
    @media ${media.tabletLandscape} {
      grid-column: 1 / 8;
    }
  }

  .blockquote__attribution {
    ${smallParagraphTextStyles}
    margin-top: 15px;

    @media ${media.tabletLandscape} {
      margin-top: -8px;
    }
  }

  .blockquote__text {
    font-family: ${font.primaryItalic};
    font-size: ${font.sizeAlpha};
    line-height: 30px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    @media ${media.tabletLandscape} {
      font-size: ${font.sizeMega};
      line-height: 55px;
    }
  }
`;

export const BlockquoteWrapper = styled.figure`
  color: ${props => props.theme.secondary};
  background-color: ${props => props.theme.primary};
  ${themedMarginTop('123px')};
  ${themedMarginBottom('98px')};

  @media ${media.tabletLandscape} {
    ${themedMarginTop('171px')};
    ${themedMarginBottom('186px')};
  }
`;
