import React from 'react';
import { Image } from '../parts/image';
import { WorkCardFragmentFragment } from '../../../types/gatsby-types';
import { Link } from '../navigation/link';
import { StyledWorkCard } from './work-card.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import classNames from 'classnames';
import { StyledLargeParagraph } from '../../abstracts/typography.styles';
import { Ratio } from '../../helpers/image-helpers';
import { graphql } from 'gatsby';

export const WorkCardFragment = graphql`
  fragment WorkCardFragment on Nmbl_Work {
    contentItemId
    alias {
      alias
    }
    highlightColor
    displayText
    featuredImage {
      ...ImageFragment
    }
    sortOrder
  }
`;

export interface IWorkCardProps {
  className?: string;
  path: string;
  ratio: Ratio;
  mobileRatio: Ratio;
}

export const WorkCard: React.FC<IWorkCardProps &
  WorkCardFragmentFragment> = props => (
  <StyledWorkCard
    className={classNames(
      'work-card',
      props.ratio && `work-card--${props.ratio}`,
      props.className
    )}
    style={
      props.highlightColor
        ? {
            backgroundColor: `#${props.highlightColor.replace('#', '')}`,
          }
        : {
            backgroundColor: `#111114`,
          }
    }
  >
    <Link to={props.path} className="work-card__link">
      <StyledLargeParagraph as="h3" className="work-card__title">
        <StyledInViewElement>{props.displayText}</StyledInViewElement>
      </StyledLargeParagraph>
      {props.featuredImage?.src && (
        <StyledInViewElement>
          <Image
            className="work-card__image work-card__image--desktop"
            ratio={props.ratio}
            image={props.featuredImage}
            alt=""
            caption=""
          />
          <Image
            className="work-card__image work-card__image--mobile"
            ratio={props.mobileRatio}
            image={props.featuredImage}
            alt=""
            caption=""
          />
        </StyledInViewElement>
      )}
    </Link>
  </StyledWorkCard>
);
