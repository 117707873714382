import * as React from 'react';
import { InViewContext } from './in-view-container.context';
import { useWindowContext } from './window-provider.context';
import {
  sortElementsByPosition,
  shouldEnter,
  shouldSkipToFinalState,
} from './in-view-container.helpers';
import { usePageTransitionContext } from '../page-transition/page-transition-provider';
import { useEventCallback } from '../../helpers/hooks';

const TRANSITION_STAGGER_TIME = 50;

export interface IInViewElement {
  yPos: number | undefined;
  xPos: number | undefined;
  height: number | undefined;
  isVisible: boolean;
  inViewCallback: () => void;
  animationCallback: () => void;
  isDoneCallback: () => void;
  ref: React.RefObject<Element>;
}

export type InViewReducerActions =
  | {
      type: 'ADD_ELEMENT';
      element: IInViewElement;
    }
  | { type: 'UPDATE_ELEMENT'; element: IInViewElement }
  | { type: 'REMOVE_ELEMENT'; element: IInViewElement };

const findElementIndex = (
  elements: IInViewElement[],
  searchElement: IInViewElement
) =>
  elements.findIndex(element => {
    return element.ref && element.ref === searchElement.ref;
  });

export const elementReducer = (
  name: string
): React.Reducer<IInViewElement[], InViewReducerActions> => (
  elements,
  action
) => {
  console.debug(`${name}: ${action.type}: `, elements);
  switch (action.type) {
    case 'ADD_ELEMENT':
      return [...elements, action.element].sort(sortElementsByPosition);

    case 'UPDATE_ELEMENT':
      const updatedElements = [...elements];
      const index = findElementIndex(updatedElements, action.element);

      console.debug(`${name}: UPDATING_ELEMENT: ${index}`);

      if (index) {
        updatedElements[index] = action.element;

        return updatedElements.sort(sortElementsByPosition);
      }

      break;

    case 'REMOVE_ELEMENT':
      const elementsLeft = [...elements];
      const removeIndex = findElementIndex(elements, action.element);

      console.debug(`${name}: REMOVING_ELEMENT: ${removeIndex}`);

      if (removeIndex !== -1) {
        const removedElement = elementsLeft.splice(removeIndex, 1);

        console.debug(`${name}: REMOVED_ELEMENT": ${removedElement}`);

        return elementsLeft;
      }
  }

  return elements;
};

export const InViewElementReducer = elementReducer('InViewElementReducer');
export const TransitioningElementReducer = elementReducer(
  'TransitioningElementReducer'
);

export const InViewContainer: React.FC = props => {
  const {
    scrollState,
    windowSize,
    transitioningElementsCallback,
  } = useWindowContext();
  const { isTransitioning: isPageTransitioning } = usePageTransitionContext();
  const [isAnimating, setIsAnimating] = React.useState(false);
  // Elements that are in the viewport
  const [inViewElements, dispatchInViewElements] = React.useReducer(
    InViewElementReducer,
    []
  );
  // Elements that are transitioning
  const [
    transitioningElements,
    dispatchTransitioningElements,
  ] = React.useReducer(TransitioningElementReducer, []);
  // prevent stagnation
  const transitionTimestamp = React.useRef(0);
  const [nextAnimationFrame, setNextAnimationFrame] = React.useState<number>();
  const scrollY = scrollState?.currentPosition.scrollY;

  React.useEffect(() => {
    console.debug(
      'InViewContainer: testInViewElements',
      isPageTransitioning,
      scrollY,
      isAnimating
    );
    !isPageTransitioning &&
      typeof scrollY !== 'undefined' &&
      inViewElements.forEach((element, index) => {
        if (shouldEnter(element, scrollY, windowSize)) {
          element.inViewCallback();

          dispatchTransitioningElements({
            type: 'ADD_ELEMENT',
            element,
          });
          dispatchInViewElements({
            type: 'REMOVE_ELEMENT',
            element,
          });

          setIsAnimating(true);
        } else if (shouldSkipToFinalState(element, scrollY)) {
          element.isDoneCallback();

          // maybe remove existing element
          dispatchInViewElements({
            type: 'REMOVE_ELEMENT',
            element,
          });
          dispatchTransitioningElements({
            type: 'REMOVE_ELEMENT',
            element,
          });
        }
      });
  }, [isPageTransitioning, inViewElements, scrollY, windowSize, isAnimating]);

  // useEventCallback, because this might be called in the future and
  // should always use the latest underlying values
  const transitionElementsStep = useEventCallback(
    (timestamp: number) => {
      console.debug('InViewContainer: transitionElementsStep');

      if (transitioningElements.length > 0) {
        if (
          timestamp - transitionTimestamp.current >=
          TRANSITION_STAGGER_TIME
        ) {
          const [currentElement] = transitioningElements;

          if (currentElement) {
            currentElement.animationCallback();

            // delay next transition
            transitionTimestamp.current = timestamp;

            dispatchTransitioningElements({
              type: 'REMOVE_ELEMENT',
              element: currentElement,
            });
          }
        }

        setNextAnimationFrame(
          window.requestAnimationFrame(transitionElementsStep)
        );
      } else {
        console.debug(
          'InViewContainer: transitionElementsStep: Done Animating'
        );
        setNextAnimationFrame(undefined);
        setIsAnimating(false);
      }
    },
    [transitioningElements, isPageTransitioning]
  );

  // note: transitionElementsStep will actually never change refs due to useEventCallback
  React.useEffect(() => {
    console.debug('InViewContainer: maybeRequestTransitionElementsStep');
    if (!isPageTransitioning && isAnimating && !nextAnimationFrame) {
      setNextAnimationFrame(
        window.requestAnimationFrame(transitionElementsStep)
      );
    }
  }, [
    isAnimating,
    isPageTransitioning,
    transitionElementsStep,
    nextAnimationFrame,
  ]);

  React.useEffect(() => {
    transitioningElementsCallback &&
      transitioningElementsCallback(transitioningElements.length);
  }, [transitioningElements.length, transitioningElementsCallback]);

  const registerElement = React.useCallback((element: IInViewElement) => {
    console.debug('InViewContainer: registerElement');
    dispatchInViewElements({
      type: 'ADD_ELEMENT',
      element,
    });
  }, []);

  const updateElement = React.useCallback((element: IInViewElement) => {
    console.debug('InViewContainer: updateElement');
    dispatchInViewElements({
      type: 'UPDATE_ELEMENT',
      element,
    });
  }, []);

  return (
    <InViewContext.Provider
      value={{
        registerElement,
        updateElement,
      }}
    >
      {props.children}
    </InViewContext.Provider>
  );
};
