import React from 'react';
import { WidgetFragment_Nmbl_ColumnsWidget_Fragment } from '../../../types/gatsby-types';
import { filterNotEmpty } from '../../helpers/arrayHelpers';
import { Widget, WidgetContentItem } from '../widgets';
import { StyledColumns } from './columns.styles';
import { graphql } from 'gatsby';
import {
  isStringRatio,
  Ratio,
  aspectRatios,
} from '../../helpers/image-helpers';
import { StyledWrapper } from '../parts/wrapper.styles';
import classNames from 'classnames';

export const ColumnSpanPartFragment = graphql`
  fragment ColumnSpanPartFragment on Nmbl_ColumnSpanPart {
    columnSpan
  }
`;
export const ColumnsWidgetFragment = graphql`
  fragment ColumnsWidgetFragment on Nmbl_ColumnsWidget {
    bag {
      contentItems {
        contentItemId
        contentType
        ... on Nmbl_EditorWidget {
          ...EditorWidgetFragment
        }
        ... on Nmbl_ImageWidget {
          ...ImageWidgetFragment
        }
        ... on Nmbl_VideoWidget {
          ...VideoWidgetFragment
        }
      }
    }
    theme {
      ...ThemeFragment
    }
  }
`;
export type WidgetColumn = 'one' | 'two';

export type WidgetWithColumnsProps<T> = T & {
  columns?: WidgetColumn;
  /**
   * Row-level Padding Bottoms [mobile, desktop]
   */
  paddingBottoms?: [number | undefined, number | undefined];
};

const stringIsColumn = (value: string | undefined): value is WidgetColumn => {
  return value === 'one' || value === 'two';
};

export const selectColumnSpan = (contentItem: WidgetContentItem) => {
  let columns: WidgetColumn = 'one';

  if (
    contentItem.__typename === 'Nmbl_ImageWidget' ||
    contentItem.__typename === 'Nmbl_VideoWidget'
  ) {
    const columnsValue = contentItem.columnSpan?.columnSpan;

    if (stringIsColumn(columnsValue)) {
      columns = columnsValue;
    } else {
      const ratio =
        contentItem.__typename === 'Nmbl_VideoWidget'
          ? contentItem.desktopVideo?.ratio
          : contentItem.imageWithRatio?.ratio;

      if (isStringRatio(ratio) && ratio === 'double') {
        columns = 'two';
      }
    }
  }

  return columns;
};

/**
 *
 * @param contentItems
 * @param index
 * @param columnIndex the index of the columns, for example
 * ```
 * [ col1 ] [ col2 ]
 * [     col3      ]
 * [ col5 ] [ col6 ]
 * ```
 */
const selectAdjacentColumn = (
  contentItems: WidgetContentItem[],
  index: number,
  columnIndex: number
) => {
  let adjacentColumn: WidgetContentItem | undefined;

  const sourceColumns = selectColumnSpan(contentItems[index]);

  if (sourceColumns === 'one') {
    // if odd column, check next column, else check prev column
    const maybeAdjacentColumn =
      columnIndex % 2 === 1 ? contentItems[index + 1] : contentItems[index - 1];

    if (
      maybeAdjacentColumn &&
      selectColumnSpan(contentItems[index]) === 'one'
    ) {
      adjacentColumn = maybeAdjacentColumn;
    }
  }

  return adjacentColumn;
};

export const selectRatio = (maybeRatio: string | undefined): Ratio =>
  isStringRatio(maybeRatio) ? maybeRatio : 'default';

export const selectPaddingBottomFromWidget = (
  contentItem: WidgetContentItem
): [number | undefined, number | undefined] => {
  if (contentItem.__typename === 'Nmbl_ImageWidget') {
    const mobileImage =
      contentItem.mobileImageWithRatio ?? contentItem.imageWithRatio;
    const mobileRatio = selectRatio(mobileImage?.ratio);
    const mobileSrc = mobileImage?.image?.src;
    const mobileAspectRatio =
      mobileSrc && mobileSrc[mobileRatio]?.fluid?.aspectRatio;
    const desktopImage =
      contentItem.imageWithRatio ?? contentItem.mobileImageWithRatio;
    const desktopRatio = selectRatio(desktopImage?.ratio);
    const desktopSrc = desktopImage?.image?.src;
    const desktopAspectRatio =
      desktopSrc && desktopSrc[desktopRatio]?.fluid?.aspectRatio;

    return [
      mobileAspectRatio && 1 / mobileAspectRatio,
      desktopAspectRatio && 1 / desktopAspectRatio,
    ];
  } else if (contentItem.__typename === 'Nmbl_VideoWidget') {
    const mobileVideo = contentItem.mobileVideo ?? contentItem.desktopVideo;
    const desktopVideo = contentItem.desktopVideo ?? contentItem.mobileVideo;
    const mobileRatio = selectRatio(mobileVideo?.ratio);
    const desktopRatio = selectRatio(desktopVideo?.ratio);

    const mobileAspectRatio = mobileRatio && aspectRatios[mobileRatio];
    const desktopAspectRatio = desktopRatio && aspectRatios[desktopRatio];

    return [
      mobileAspectRatio && 1 / mobileAspectRatio,
      desktopAspectRatio && 1 / desktopAspectRatio,
    ];
  }

  return [undefined, undefined];
};

export const selectPaddingBottomFromWidgets = (
  contentItem: WidgetContentItem,
  adjacentContentItem?: WidgetContentItem
): [number, number] => {
  const contentItemPaddingBottoms = selectPaddingBottomFromWidget(contentItem);
  const adjacentContentItemPaddingBottoms =
    adjacentContentItem && selectPaddingBottomFromWidget(adjacentContentItem);
  return [
    Math.max(
      contentItemPaddingBottoms[0] ?? 0,
      (adjacentContentItemPaddingBottoms &&
        adjacentContentItemPaddingBottoms[0]) ??
        0
    ),
    Math.max(
      contentItemPaddingBottoms[1] ?? 0,
      (adjacentContentItemPaddingBottoms &&
        adjacentContentItemPaddingBottoms[1]) ??
        0
    ),
  ];
};

export const ColumnsWidget: React.FC<WidgetFragment_Nmbl_ColumnsWidget_Fragment> = props => {
  const contentItems = props.bag?.contentItems?.filter(filterNotEmpty) ?? [];
  const editorOnly =
    contentItems.length > 0 &&
    contentItems.every(
      contentItem => contentItem.__typename === 'Nmbl_EditorWidget'
    );
  let columnIndex = 1;

  return (
    <StyledWrapper
      as={StyledColumns}
      className={classNames(
        // todo: rename to columns-widget
        'editor-columns',
        editorOnly && 'editor-columns--editor-only'
      )}
    >
      {contentItems.map((contentItem, index) => {
        const columnSpan = selectColumnSpan(contentItem);
        const adjacentColumn = selectAdjacentColumn(
          contentItems,
          index,
          columnIndex
        );

        const paddingBottoms = selectPaddingBottomFromWidgets(
          contentItem,
          adjacentColumn
        );

        columnIndex += columnSpan === 'two' ? 2 : 1;

        return (
          <Widget
            key={contentItem.contentItemId}
            {...(contentItem as any)}
            columns={columnSpan}
            paddingBottoms={paddingBottoms}
          />
        );
      })}
    </StyledWrapper>
  );
};
