import styled from 'styled-components';
import { h1Styles } from '../../abstracts/typography.styles';
import { grid6, grid12 } from '../../abstracts/theme.styles';
import { media } from '../../abstracts/media-settings.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import { smallParagraphStyles } from '../../abstracts/typography.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledTeamWidget = styled.div`
  ${themedMarginTop('95px;')}
  ${themedMarginBottom('80px')}
  font-family: ${font.primary};

  @media ${media.tabletLandscape} {
    ${themedMarginTop('141px;')}
    ${themedMarginBottom('111px')}
  }

  .team-widget__header {
    @media ${media.tabletLandscape} {
      ${grid12}
    }
  }

  .team-widget__title {
    ${h1Styles}
    margin-top: 0;
    margin-bottom: 60px;

    @media ${media.tabletLandscape} {
      grid-column: auto / span 4;
      margin-top: 0;
      margin-bottom: 100px;
    }
  }

  .team-widget__team-members {
    @media ${media.tabletLandscape} {
      ${grid6}
    }

    @media ${media.ieTabletLandscape} {
      max-width: 800px;
    }
  }

  .team-widget__team-member {
    margin-bottom: 64px;

    @media ${media.tabletLandscape} {
      grid-column: auto / span 2;
      align-self: end;
      margin-bottom: 33px;
    }
  }

  .team-member__image--desktop {
    display: none;

    @media ${media.tabletLandscape} {
      display: block;
    }
  }

  .team-member__image--mobile {
    display: block;

    @media ${media.tabletLandscape} {
      display: none;
    }
  }

  h3 {
    margin-top: 19px;
    margin-bottom: 0;

    @media ${media.tabletLandscape} {
      margin-bottom: 0;
    }
  }

  h5 {
    ${smallParagraphStyles}
    margin-top: -2px;
    margin-bottom: 0;

    @media ${media.tabletLandscape} {
      margin-top: -4px;
      margin-bottom: 0;
    }
  }
`;
