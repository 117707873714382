import React from 'react';
import { WidgetFragment_Nmbl_ServicesWidget_Fragment } from '../../../types/gatsby-types';
import { CmsContent } from '../parts/cms-content';
import { WorkContext } from '../templates/work.context';
import { StyledServicesWidget } from './services.styles';
import { StyledLink } from '../../components/navigation/link.styles';
import ArrowSvg from '../../svg/arrowSvg.svg';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';

export const ServicesWidgetFragment = graphql`
  fragment ServicesWidgetFragment on Nmbl_ServicesWidget {
    client
    title
    subtitle
    services
    htmlBody {
      ...HtmlBodyFragment
    }
    link {
      label
      url
    }
    theme {
      ...ThemeFragment
    }
  }
`;

const selectServiceList = (serviceText: string | undefined) =>
  serviceText?.split('\n\n').map(chunkedText => chunkedText.split('\n'));

export const ServicesWidget: React.FC<WidgetFragment_Nmbl_ServicesWidget_Fragment> = props => {
  const workContext = React.useContext(WorkContext);
  const services = React.useMemo(() => selectServiceList(props.services), [
    props.services,
  ]);
  return (
    <StyledWrapper
      as={StyledServicesWidget}
      className="services"
      style={
        workContext && workContext.work.highlightColor
          ? {
              backgroundColor: `#${workContext.work.highlightColor.replace(
                '#',
                ''
              )}`,
            }
          : undefined
      }
    >
      <header className="services__header">
        <StyledInViewElement>
          {props.client && (
            <h4 className="services__client-name">{props.client}</h4>
          )}
          <h2 className="services__title">{props.title}</h2>
        </StyledInViewElement>
      </header>
      <StyledInViewElement className="services__content">
        <h4 className="services__subtitle">{props.subtitle}</h4>
        <div className="services__list">
          {services &&
            services.map((servicesList, i, allServices) => (
              <span key={i}>
                {servicesList.map((service, j) => (
                  <span className="services__list-item" key={j}>
                    {service}
                  </span>
                ))}
              </span>
            ))}
        </div>
        <CmsContent
          className="services__description"
          html={props.htmlBody?.html}
        />
        {props.link && props.link.url && (
          <StyledLink className="services__link" to={props.link.url}>
            <span className="services__link-text">
              {props.link.label || 'View more'}
            </span>
            {props.link.label && (
              <span className="services__link-icon">
                <ArrowSvg />
              </span>
            )}
          </StyledLink>
        )}
      </StyledInViewElement>
    </StyledWrapper>
  );
};
