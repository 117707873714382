import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import {
  h1Styles,
  h3Styles,
  boldParagraph,
  smallParagraphTextStyles,
} from '../../abstracts/typography.styles';
import { grid12 } from '../../abstracts/theme.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledServicesWidget = styled.div`
  ${themedMarginTop('143px')}
  ${themedMarginBottom('147px')}

  @media ${media.tabletLandscape} {
    ${grid12}
    ${themedMarginTop('190px')}
    ${themedMarginBottom('151px')}
  }

  @media ${media.ieTabletLandscape} {
    max-width: 800px;
  }

  .services__header {
    @media ${media.tabletLandscape} {
      grid-column: 1 / 5;
    }
  }

  .services__list {
    font-family: ${font.primary};
    margin-bottom: 48px;
    overflow: hidden;
    /* Allows for the margin-right of last-items */
    width: calc(100% + 30px);

    @media ${media.tabletLandscape} {
      margin-bottom: 67px;
      width: 100%;
    }

    .services__list-item {
      position: relative;
      display: inline-block;
      margin-right: 25px;

      // Target firefox only
      @media screen and (min--moz-device-pixel-ratio:0) {
        white-space: nowrap;
      }

      &::before {
        content: '';
        height: 1px;
        width: 9px;
        transform: rotate(-60deg);
        background-color: ${props => props.theme.secondary};
        display: inline-block;
        border-radius: 2px;
        opacity: 0.3;
        top: 12px;
        left: -17px;
        position: absolute;

        @media ${media.tabletLandscape} {
          left: -19px;
        }
      }
    }
  }

  .services__client-name {
    ${h3Styles}
    margin-top: 0;
    margin-bottom: 6px;
  }

  .services__title {
    ${h1Styles}
    margin-top: 0;
    margin-bottom: 21px;
  }

  .services__content {
    @media ${media.tabletLandscape} {
      grid-column: 6 / -1;
    }

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .services__subtitle {
    ${boldParagraph}
    margin-top: 3px;
    margin-bottom: 6px;

    @media ${media.tabletLandscape} {
      margin-bottom: 21px;
    }
  }

  .services__link {
    ${smallParagraphTextStyles}
    display: inline-block;
    border-bottom: 0;
    margin-top: 22px;

    @media ${media.tabletLandscape} {
      margin-top: 32px;
    }

    &:hover {
      .services__link-text {
        border-bottom: 1px solid ${props => props.theme.secondaryOpaque};
      }

      .services__link-icon {
        transition: opacity 0.2s linear;
        opacity: 1;
      }
    }

    .template--work & {
      margin-top: 0;
    }
  }

  .services__description {
    margin-bottom: 38px;
  }

  .services__link-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    vertical-align: middle;
    fill: ${props => props.theme.secondary};
    opacity: 0.3;
  }

  .services__link-text {
    border-bottom: 1px solid ${props => props.theme.secondary};
    transition: border-bottom 0.2s linear;
  }
`;
