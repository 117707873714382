import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid, grid12 } from '../../abstracts/theme.styles';
import { smallParagraphStyles } from '../../abstracts/typography.styles';
import { color } from '../../abstracts/variables/colors.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledImageSlider = styled.div`
  ${themedMarginTop('60px')}
  ${themedMarginBottom('132px')}

  @media ${media.tabletLandscape} {
    ${themedMarginTop('171px')}
    ${themedMarginBottom('144px')}
  }

  .image-slider__slider {
    overflow: hidden;
    position: relative;
    margin-bottom: 32px;

    @media ${media.tabletLandscape} {
      margin-bottom: 48px;
    }
  }

  .image-slider__slides {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .image-slider__slides > li {
    pointer-events: none;
    width: 100%;
  }

  .image-slider__content {
    padding-right: ${grid.offset.mobile};
    padding-left: ${grid.offset.mobile};

    @media ${media.tabletLandscape} {
      ${grid12}
      padding-right: 0;
      padding-left: 0;
    }
  }

  .image-slider__arrow {
    display: inline-block;
    width: 30px;
    height: 30px;
    pointer-events: all;

    &--right {
      float: right;
      transform: rotate(180deg);
    }

    path {
      fill: ${color.white};
    }

    circle {
      fill: ${color.black};
      opacity: 0.1;
    }
  }

  .image-slider__arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -15px;
    padding-right: 15px;
    padding-left: 15px;
    pointer-events: none;

    @media ${media.tabletLandscape} {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .image-slider__pagination {
    ${smallParagraphStyles}
    opacity: 0.7;

    @media ${media.tabletLandscape} {
      grid-column: auto / span 5;
      margin-bottom: 0;
    }
  }

  .image-slider__html {
    @media ${media.tabletLandscape} {
      grid-column: auto / span 4;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;
