import React from 'react';
import { WidgetFragment_Nmbl_MarqueeWidget_Fragment } from '../../../types/gatsby-types';
import { WorkContext } from '../templates/work.context';
import { StyledMarquee, StyledMarqueeImage } from './marquee.styles';
import {
  Heading1,
  StyledLargeParagraph,
} from '../../abstracts/typography.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import classNames from 'classnames';
import { graphql } from 'gatsby';

export const MarqueeWidgetFragment = graphql`
  fragment MarqueeWidgetFragment on Nmbl_MarqueeWidget {
    direction
    image {
      image {
        ...ImageFragment
      }
      alt
      caption
    }
    mobileImage {
      image {
        ...ImageFragment
      }
      alt
      caption
    }
    title
    subtitle
    theme {
      ...ThemeFragment
    }
  }
`;

export const MarqueeWidget: React.FC<WidgetFragment_Nmbl_MarqueeWidget_Fragment> = props => {
  const workContext = React.useContext(WorkContext);
  // fallback to either image for both
  const mobileImage = props.mobileImage?.image?.src
    ? props.mobileImage
    : props.image;
  const desktopImage = props.image?.image?.src
    ? props.image
    : props.mobileImage;

  return (
    <StyledMarquee
      className={classNames(
        'marquee-widget',
        props.direction && `marquee-widget--${props.direction}`
      )}
      style={
        workContext && workContext.work.highlightColor
          ? {
              backgroundColor: `#${workContext.work.highlightColor.replace(
                '#',
                ''
              )}`,
            }
          : undefined
      }
    >
      <StyledInViewElement>
        {mobileImage?.image?.src && (
          <StyledMarqueeImage
            className="marquee-widget__image marquee-widget__image--mobile"
            ratio="tall"
            image={mobileImage.image}
            alt={mobileImage.alt}
            caption={mobileImage.caption}
          />
        )}
        {desktopImage?.image?.src && (
          <StyledMarqueeImage
            className="marquee-widget__image marquee-widget__image--desktop"
            ratio="wide"
            image={desktopImage.image}
            alt={desktopImage.alt}
            caption={desktopImage.caption}
          />
        )}
      </StyledInViewElement>
      <StyledWrapper
        style={
          workContext && workContext.work.highlightColor
            ? {
                backgroundColor: `#${workContext.work.highlightColor.replace(
                  '#',
                  ''
                )}`,
              }
            : undefined
        }
      >
        <StyledInViewElement>
          {props.title && (
            <div className="marquee-widget__content">
              <Heading1>{props.title}</Heading1>
              {props.subtitle && (
                <StyledLargeParagraph>{props.subtitle}</StyledLargeParagraph>
              )}
            </div>
          )}
        </StyledInViewElement>
      </StyledWrapper>
    </StyledMarquee>
  );
};
