import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid8 } from '../../abstracts/theme.styles';
import { themedMarginBottom } from '../../helpers/theme-helpers';

export const StyledRecentWork = styled.div`
  ${themedMarginBottom('71px')}

  @media ${media.tabletLandscape} {
    ${themedMarginBottom('152px')}
  }

  // ie11
  @media screen and (-ms-high-contrast: none) and (min-width: 1024px),
  screen and (-ms-high-contrast: active) and (min-width: 1024px) {
    margin-top: 300px !important;
  }

  .recent-work__list {
    @media ${media.tabletLandscape} {
      ${grid8}
    }

    @media ${media.ieTabletLandscape} {
      max-width: 800px;
    }

    li {
      &:nth-of-type(3n + 1) {
        @media ${media.tabletLandscape} {
          grid-column: 1 / 5;
          margin-bottom: 100px;
          align-self: end;
        }
      }

      &:nth-of-type(3n + 2) {
        @media ${media.tabletLandscape} {
          grid-column: 5 / 9;
        }

        @media ${media.ieTabletLandscape} {
          margin-bottom: 100px;
        }
      }

      &:nth-of-type(3n + 3) {
        @media ${media.tabletLandscape} {
          grid-column: 1 / 9;
        }

        @media ${media.ieTabletLandscape} {
          margin-bottom: 100px;
        }
      }
    }
  }

  .recent-work__link {
    display: inline-block;
    margin-top: 60px;

    @media ${media.tabletLandscape} {
      margin-top: 115px;
    }
  }
`;

export const StyledRecentWorkListItem = styled.li`
  & + li {
    margin-top: 10px;

    @media ${media.tabletLandscape} {
      margin-top: 0;
    }
  }
`;
