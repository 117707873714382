import React from 'react';
import classNames from 'classnames';

export interface IMainProps {
  template: string;
}

export const Main: React.FC<IMainProps> = props => (
  <main className={classNames('template', `template--${props.template}`)}>
    {props.children}
  </main>
);
