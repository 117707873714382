import React from 'react';
import { Image } from '../parts/image';
import { WidgetFragment_Nmbl_ImageWidget_Fragment } from '../../../types/gatsby-types';
import { StyledImageWidget } from './image-widget.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';
import { WidgetWithColumnsProps } from './columns';
import classNames from 'classnames';

export const ImageWidgetFragment = graphql`
  fragment ImageWidgetFragment on Nmbl_ImageWidget {
    imageWithRatio {
      image {
        ...ImageFragment
      }
      ratio
      alt
      caption
    }
    mobileImageWithRatio {
      image {
        ...ImageFragment
      }
      ratio
      alt
      caption
    }
    theme {
      ...ThemeFragment
    }
    columnSpan {
      ...ColumnSpanPartFragment
    }
  }
`;

export const ImageWidget: React.FC<WidgetWithColumnsProps<
  WidgetFragment_Nmbl_ImageWidget_Fragment
>> = props => {
  const desktopImage = props.imageWithRatio?.image?.src
    ? props.imageWithRatio
    : props.mobileImageWithRatio;
  const mobileImage = props.mobileImageWithRatio?.image?.src
    ? props.mobileImageWithRatio
    : props.imageWithRatio;
  const [, desktopPaddingBottom] = props.paddingBottoms ?? [];

  return (
    <StyledImageWidget
      className={classNames(
        'image-widget',
        props.columns && `columns--${props.columns}`,
        mobileImage?.caption || desktopImage?.caption
          ? 'image-widget--has-caption'
          : ''
      )}
    >
      <StyledInViewElement>
        {mobileImage && mobileImage.ratio && (
          <Image
            className="image-widget__image image-widget__image--mobile"
            image={mobileImage.image}
            ratio={mobileImage.ratio}
            alt={mobileImage.alt}
            caption={mobileImage.caption}
          />
        )}
        {desktopImage && desktopImage.ratio && (
          <Image
            className="image-widget__image image-widget__image--desktop"
            image={desktopImage.image}
            ratio={desktopImage.ratio}
            alt={desktopImage.alt}
            caption={desktopImage.caption}
            paddingBottom={desktopPaddingBottom}
          />
        )}
      </StyledInViewElement>
    </StyledImageWidget>
  );
};
