import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledStats = styled.ul`
  font-family: ${font.primary};
  ${themedMarginTop('48px')}
  ${themedMarginBottom('95px')}

  @media ${media.tabletLandscape} {
    ${grid12}
    ${themedMarginTop('35px')}
    ${themedMarginBottom('150px')}
  }

  .stats-widget__title {
    font-size: 50px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .stats-widget__label {
    margin-top: -13px;
    margin-bottom: 0;
  }

  .stats-widget__item {
    @media ${media.tabletLandscape} {
      grid-column: auto / span 2;
    }
  }

  .stats-widget__item + .stats-widget__item {
    margin-top: 44px;

    @media ${media.tabletLandscape} {
      margin-top: 0;
    }
  }
`;
