import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { smallParagraphStyles } from '../../abstracts/typography.styles';

export const StyledImageWidget = styled.div`
  .image-widget__image--desktop {
    display: none;

    @media ${media.tabletLandscape} {
      display: block;
    }
  }

  .image-widget__image--mobile {
    display: block;

    @media ${media.tabletLandscape} {
      display: none;
    }
  }

  figcaption {
    ${smallParagraphStyles}
    margin-top: 24px;
    color: ${props => props.theme.secondary};

    @media ${media.tabletLandscape} {
      margin-top: 34px;
      width: 75%;
    }
  }
`;
