import React from 'react';
import { WorkCard } from '../work/work-card';
import { WorkListContext } from '../templates/work-list.context';
import {
  selectWorkListUrl,
  selectWorkUrl,
  selectOrderedRatio,
  mobileOrderedRatios,
  sortByWorkSortOrder,
} from '../../helpers/work-selectors';
import { WidgetFragment_Nmbl_WorkGridWidget_Fragment } from '../../../types/gatsby-types';
import { StyledLink } from '../navigation/link.styles';
import { filterNotEmpty } from '../../helpers/arrayHelpers';
import { StyledWorkGrid } from './work-grid.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import classNames from 'classnames';

export const WorkGridWidget: React.FC<WidgetFragment_Nmbl_WorkGridWidget_Fragment> = () => {
  const workListContext = React.useContext(WorkListContext);

  if (!workListContext) {
    throw new Error(
      'Cannot use Work Grid Widget without WorkListContext.Provider'
    );
  }

  return (
    <StyledWrapper mobilePadding="0" as={StyledWorkGrid} className="work-grid">
      <ul className="work-grid__list">
        {workListContext.nmbl.work
          ?.filter(filterNotEmpty)
          ?.sort(sortByWorkSortOrder)
          .map((work, index) => (
            <li
              className={classNames(
                'work-grid__item',
                `work-grid__item--${selectOrderedRatio(index)}`
              )}
              key={work.contentItemId}
            >
              <WorkCard
                className="work-grid__card work-grid__card--desktop"
                ratio={selectOrderedRatio(index)}
                mobileRatio={selectOrderedRatio(index, mobileOrderedRatios)}
                path={selectWorkUrl(
                  `/${workListContext.page.alias?.alias}`,
                  work.alias?.alias
                )}
                {...work}
              />
            </li>
          ))}
      </ul>
      {workListContext.numPages > 1 && (
        <nav className="work-grid__nav">
          <ul>
            {workListContext.currentPage > 1 && (
              <li className="work-grid__prev">
                <StyledLink
                  to={selectWorkListUrl(
                    `/${workListContext.page.alias?.alias}`,
                    workListContext.currentPage - 1
                  )}
                >
                  Previous
                </StyledLink>
              </li>
            )}
            {workListContext.currentPage < workListContext.numPages && (
              <li className="work-grid__next">
                <StyledLink
                  to={selectWorkListUrl(
                    `/${workListContext.page.alias?.alias}`,
                    workListContext.currentPage + 1
                  )}
                >
                  Next
                </StyledLink>
              </li>
            )}
          </ul>
        </nav>
      )}
    </StyledWrapper>
  );
};
