import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid6, grid12 } from '../../abstracts/theme.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledColumns = styled.div`
  ${themedMarginTop('80px')};
  ${themedMarginBottom('60px')};
  color: ${props => props.theme.primary};

  @media ${media.tabletLandscape} {
    ${themedMarginTop('100px')};
    ${themedMarginBottom('120px')};
    ${grid6}
  }

  @media ${media.ieTabletLandscape} {
    max-width: 800px;
  }

  div + div {
    margin-top: 30px;

    @media ${media.tabletLandscape} {
      margin-top: 0;
    }
  }

  .editor-wrapper {
    padding-right: 0;
    padding-left: 0;

    @media ${media.tabletLandscape} {
      grid-column: auto / span 3;
      margin-right: 90px;
      padding-right: 0;
      padding-left: 0;
    }

    + .editor-wrapper {
      margin-top: 90px;

      @media ${media.tabletLandscape} {
        margin-top: 0;
      }
    }
  }

  &.editor-columns--editor-only {
    @media ${media.tabletLandscape} {
      ${grid12}
    }

    .editor-wrapper {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 5;
      }
    }
  }

  .columns--one {
    @media ${media.tabletLandscape} {
      grid-column: auto / span 3;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .columns--two {
    @media ${media.tabletLandscape} {
      grid-column: auto / span 6;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .image-wrapper,
  .video-wrapper {
    @media ${media.tabletLandscape} {
      position: relative;
      height: 0;
    }
  }

  .gatsby-image-wrapper,
  .video-widget__video {
    @media ${media.tabletLandscape} {
      position: absolute !important;
      width: 100%;
      bottom: 0;
    }
  }
`;
