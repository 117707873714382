import { IInViewElement } from './in-view-container';
import { Dimensions } from './window-provider';

const BOTTOM_OFFSET_PERCENT = 10;

// To do after registering elements and also after resize
export const sortElementsByPosition = (
  first: IInViewElement,
  second: IInViewElement
) => {
  if (first.yPos !== second.yPos) {
    return (first.yPos ?? 0) - (second.yPos ?? 0);
  }

  return (first.xPos ?? 0) - (second.xPos ?? 0);
};

export const shouldSkipToFinalState = (
  element: IInViewElement,
  scrollPosition: number
) => {
  if (element.yPos !== undefined && element.height !== undefined) {
    const bottomPosition = element.yPos + element.height;

    return bottomPosition < scrollPosition;
  }

  return false;
};

// Checks if element should transition in
export const shouldEnter = (
  element: IInViewElement,
  scrollPosition: number,
  windowSize: Dimensions | undefined
) => {
  if (element.yPos !== undefined && element.height !== undefined) {
    const windowHeight = windowSize ? windowSize.height : 0;
    const yPos = element.yPos + windowHeight * (BOTTOM_OFFSET_PERCENT / 100);
    const bottomPosition = element.yPos + element.height;

    return (
      (yPos > scrollPosition || bottomPosition > scrollPosition) &&
      yPos < scrollPosition + windowHeight
    );
  }

  return false;
};
