import { Ratio } from './image-helpers';
import { WorkCardFragmentFragment } from '../../types/gatsby-types';
import { WorkSlice } from '../../gatsby-node';
import { sortByDescending, sortBySelected } from './arrayHelpers';

export const selectWorkListUrl = (prefix: string | undefined, page: number) =>
  `${prefix}${page <= 1 ? `` : `/${page}`}`;
export const selectWorkUrl = (
  prefix: string | undefined,
  slug: string | undefined
) => `${prefix}/${slug}`;

export const desktopOrderedRatios: Ratio[] = ['standard', 'tall', 'double'];
export const mobileOrderedRatios: Ratio[] = ['standard', 'tall', 'square'];

export const selectOrderedRatio = (
  index: number,
  fromRatios = desktopOrderedRatios
) =>
  fromRatios.find(
    (ratio, ratioIndex) => ratioIndex === index % fromRatios.length
  )!;

export const sortByWorkSortOrder = sortByDescending(
  sortBySelected(
    (work: WorkCardFragmentFragment | WorkSlice) => work.sortOrder ?? 0
  )
);
