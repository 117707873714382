import React from 'react';
import { WidgetFragment_Nmbl_StatsWidget_Fragment } from '../../../types/gatsby-types';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledStats } from './stats.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { graphql } from 'gatsby';

export const StatsWidgetFragment = graphql`
  fragment StatsWidgetFragment on Nmbl_StatsWidget {
    bag {
      contentItems {
        contentItemId
        contentType
        ... on Nmbl_Stat {
          heading
          label
        }
      }
    }
    theme {
      ...ThemeFragment
    }
  }
`;

export const StatsWidget: React.FC<WidgetFragment_Nmbl_StatsWidget_Fragment> = props => (
  <StyledWrapper as={StyledStats} className="stats-widget">
    {props.bag?.contentItems?.map((stat, index) =>
      stat?.__typename === 'Nmbl_Stat' ? (
        <li className="stats-widget__item" key={index}>
          <StyledInViewElement>
            <h4 className="stats-widget__title">{stat.heading}</h4>
            <p className="stats-widget__label">{stat.label}</p>
          </StyledInViewElement>
        </li>
      ) : null
    )}
  </StyledWrapper>
);
