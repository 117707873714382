import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';
import { Image } from '../../components/parts/image';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledMarquee = styled.div`
  font-family: ${font.primary};
  background-color: ${props => props.theme.primary};

  .marquee-widget__content {
    ${themedMarginTop('256px')}
    color: ${props => props.theme.secondary};

    @media ${media.tabletLandscape} {
      ${themedMarginTop('396px')}
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 23px;
  }

  p {
    margin-bottom: 0;
  }

  &.marquee-widget--vertical {
    .marquee-widget__content {
      ${themedMarginBottom('53px')}

      @media ${media.tabletLandscape} {
        ${grid12}
        ${themedMarginBottom('75px')}
        grid-gap: 20px;
      }

      h1 {
        @media ${media.tabletLandscape} {
          margin-bottom: 0;
          grid-column: 1 / span 4;
        }
      }

      p {
        @media ${media.tabletLandscape} {
          margin-top: 0;
          grid-column: 1 / span 4;
        }
      }
    }
  }

  &.marquee-widget--horizontal {
    .marquee-widget__content {
      ${themedMarginBottom('66px')};

      @media ${media.tabletLandscape} {
        ${grid12}
        ${themedMarginBottom('85px')}
      }
    }

    h1 {
      margin-bottom: 0;

      @media ${media.tabletLandscape} {
        grid-column: auto / span 5;
        margin-right: calc((1 / 5) * 100%);
      }
    }

    p {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 7;
        margin-top: 14px;
      }
    }
  }
`;

export const StyledMarqueeImage = styled(Image)`
  &.marquee-widget__image--desktop {
    display: none;

    @media ${media.tabletLandscape} {
      display: block;
    }

    .gatsby-image-wrapper {
      max-height: 75vh;
    }
  }

  &.marquee-widget__image--mobile {
    display: block;

    @media ${media.tabletLandscape} {
      display: none;
    }

    .gatsby-image-wrapper {
      max-height: 75vh;
    }
  }
`;
