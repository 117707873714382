import { useReducer, useEffect, useCallback, RefObject } from 'react';
import {
  useSwipeable,
  SwipeableHandlers,
  SwipeCallback,
  EventData,
} from 'react-swipeable';
import { useEventCallback } from '../../helpers/hooks';

// defines the time for the animation between slides in milliseconds
const DEFAULT_TRANSITION_TIME = 400;
// defines the threshold when to accept a swipe
const DEFAULT_TRANSITION_THRESHOLD = 0.3;
// defines the limit for swiping (max. the next full and a bit)
const DEFAULT_TRANSITION_LIMIT = 1.2;
// defines the milliseconds between autoPlay
const DEFAULT_AUTOPLAY_INTERVAL = 3000;

interface CarouselState {
  offset: number;
  desired: number;
  active: number;
}

const initialCarouselState: CarouselState = {
  offset: 0,
  desired: 0,
  active: 0,
};

interface CarouselNextAction {
  type: 'next';
  length: number;
}

interface CarouselPrevAction {
  type: 'prev';
  length: number;
}

interface CarouselJumpAction {
  type: 'jump';
  desired: number;
}

interface CarouselDoneAction {
  type: 'done';
}

interface CarouselDragAction {
  type: 'drag';
  offset: number;
}

type CarouselAction =
  | CarouselJumpAction
  | CarouselNextAction
  | CarouselPrevAction
  | CarouselDragAction
  | CarouselDoneAction;

function previous(length: number, current: number) {
  return (current - 1 + length) % length;
}

function next(length: number, current: number) {
  return (current + 1) % length;
}

function carouselReducer(
  state: CarouselState,
  action: CarouselAction
): CarouselState {
  switch (action.type) {
    case 'jump':
      console.info('carouselReducer: jump: ', action.desired);
      return {
        ...state,
        desired: action.desired,
      };
    case 'next':
      console.info(
        'carouselReducer: next: ',
        action.length,
        state.active,
        next(action.length, state.active)
      );
      return {
        ...state,
        desired: next(action.length, state.active),
      };
    case 'prev':
      console.info(
        'carouselReducer: prev: ',
        action.length,
        state.active,
        previous(action.length, state.active)
      );
      return {
        ...state,
        desired: previous(action.length, state.active),
      };
    case 'done':
      console.info('carouselReducer: done: ', state.desired);
      return {
        ...state,
        offset: NaN,
        active: state.desired,
      };
    case 'drag':
      console.info('carouselReducer: drag: ', action.offset);
      return {
        ...state,
        offset: action.offset,
      };
    default:
      return state;
  }
}

export interface CarouselOptions {
  slidesPresented?: number;
  autoPlay?: boolean;
  autoPlayInterval?: number;
  transitionTime?: number;
  transitionLimit?: number;
  transitionThreshold?: number;
  trackMouse?: boolean;
  trackTouch?: boolean;
  preventDefaultTouchmoveEvent?: boolean;
  ref: RefObject<HTMLElement>;
}

function makeIndices(start: number, delta: number, num: number) {
  const indices: Array<number> = [];

  while (indices.length < num) {
    indices.push(start);
    start += delta;
  }

  return indices;
}

export type CarouselHandlers = Omit<SwipeableHandlers, 'ref'> & {
  jumpTo: (slideIndex: number) => void;
  prev: () => void;
  next: () => void;
};

export interface CarouselStateSnapshot {
  activeSlide: number;
  activeSlideCount: number;
  prevSlideIndices: number[];
  nextSlideIndices: number[];
}

export const useCarousel = (
  slideCount: number,
  options: CarouselOptions
): [CarouselStateSnapshot, CarouselHandlers, React.CSSProperties] => {
  const {
    slidesPresented = 1,
    autoPlay = false,
    autoPlayInterval = DEFAULT_AUTOPLAY_INTERVAL,
    transitionTime = DEFAULT_TRANSITION_TIME,
    transitionLimit = DEFAULT_TRANSITION_LIMIT,
    transitionThreshold = DEFAULT_TRANSITION_THRESHOLD,
    trackMouse = true,
    trackTouch = true,
    preventDefaultTouchmoveEvent = false,
    ref: containerRef,
  }: CarouselOptions = options;

  const [carouselState, dispatchCarouselState] = useReducer(
    carouselReducer,
    initialCarouselState
  );

  const activeSlideCount = Math.max(1, Math.min(slidesPresented, slideCount));
  const shadowSlides = 2 * activeSlideCount;
  const totalWidth = 100 / activeSlideCount;
  const prevSlideIndices = makeIndices(slideCount - 1, -1, activeSlideCount);
  const nextSlideIndices = makeIndices(0, +1, activeSlideCount);

  const stateSnapshot = {
    activeSlide: carouselState.active,
    activeSlideCount,
    prevSlideIndices,
    nextSlideIndices,
  };

  const style: React.CSSProperties = {
    transform: 'translateX(0)',
    width: `${totalWidth * (slideCount + shadowSlides)}%`,
    left: `-${(carouselState.active + 1) * totalWidth}%`,
  };

  if (carouselState.desired !== carouselState.active) {
    const dist = Math.abs(carouselState.active - carouselState.desired);
    const pref = Math.sign(carouselState.offset || 0);
    const dir =
      (dist > slideCount / 2 ? 1 : -1) *
      Math.sign(carouselState.desired - carouselState.active);
    const shift = (totalWidth * (pref || dir)) / (slideCount + shadowSlides);
    // animation to be used when automatically sliding
    style.transition = `transform ${transitionTime}ms ease`;
    style.transform = `translateX(${shift}%)`;
  } else if (!isNaN(carouselState.offset)) {
    if (carouselState.offset !== 0) {
      style.transform = `translateX(${carouselState.offset}px)`;
    } else {
      // animation to be used when bouncing back
      style.transition = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
    }
  }

  useEffect(() => {
    if (autoPlay) {
      const timeout = setTimeout(
        () => dispatchCarouselState({ type: 'next', length: slideCount }),
        autoPlayInterval
      );
      return () => clearTimeout(timeout);
    }
  }, [autoPlay, autoPlayInterval, slideCount]);

  useEffect(() => {
    const id = setTimeout(
      () => dispatchCarouselState({ type: 'done' }),
      transitionTime
    );
    return () => clearTimeout(id);
  }, [carouselState.desired, transitionTime]);

  const onSwiping = useEventCallback(
    (e: EventData) => {
      const sign = e.deltaX > 0 ? -1 : 1;

      console.debug('useCarousel: onSwiping: ', sign, e.deltaX);

      dispatchCarouselState({
        type: 'drag',
        offset:
          sign *
          (containerRef.current && containerRef.current.firstElementChild
            ? Math.min(
                Math.abs(e.deltaX),
                transitionLimit *
                  containerRef.current.firstElementChild.clientWidth
              )
            : Math.abs(e.deltaX)),
      });
    },
    [transitionLimit]
  );

  const reset = useCallback(() => {
    // return to last slide
    dispatchCarouselState({
      type: 'drag',
      offset: 0,
    });
  }, []);

  const swiped = useEventCallback(
    (delta: number, dir: 1 | -1) => {
      const slideWidth =
        containerRef.current?.firstElementChild?.clientWidth ?? 0;
      const threshold = slideWidth * transitionThreshold;
      const absDelta = dir * delta;

      console.debug('useCarousel: swiped: ', {
        slideWidth,
        transitionThreshold,
        threshold,
        absDelta,
      });

      if (absDelta >= threshold) {
        dispatchCarouselState(
          dir > 0
            ? { type: 'next', length: slideCount }
            : { type: 'prev', length: slideCount }
        );
      } else {
        reset();
      }
    },
    [transitionThreshold, slideCount]
  );

  const onSwipedLeft = useCallback<SwipeCallback>(
    e => {
      swiped(e.deltaX, 1);
    },
    [swiped]
  );

  const onSwipedRight = useCallback<SwipeCallback>(
    e => {
      swiped(e.deltaX, -1);
    },
    [swiped]
  );

  const { ref, onMouseDown } = useSwipeable({
    onSwiping,
    onSwipedLeft,
    onSwipedRight,
    onSwipedUp: reset,
    onSwipedDown: reset,
    trackMouse,
    trackTouch,
    preventDefaultTouchmoveEvent,
  });

  // update ref on each render
  ref(containerRef.current);

  console.debug('useCarousel: Render: ', {
    slideCount,
    slidesPresented,
    shadowSlides,
    activeSlideCount,
    totalWidth,
    containerRef,
    stateSnapshot,
    style,
  });

  const jumpTo = useCallback(
    n => dispatchCarouselState({ type: 'jump', desired: n }),
    []
  );
  const prev = useEventCallback(
    () => dispatchCarouselState({ type: 'prev', length: slideCount }),
    [slideCount]
  );
  const next = useEventCallback(
    () => dispatchCarouselState({ type: 'next', length: slideCount }),
    [slideCount]
  );

  const handlers = {
    onMouseDown,
    jumpTo,
    prev,
    next,
  };

  return [stateSnapshot, handlers, style];
};
