import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid8 } from '../../abstracts/theme.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import { themedMarginBottom } from '../../helpers/theme-helpers';

export const StyledWorkGrid = styled.div`
  ${themedMarginBottom('72px')}

  @media ${media.tabletLandscape} {
    ${themedMarginBottom('153px')}
  }

  // ie11
  @media screen and (-ms-high-contrast: none) and (min-width: 1024px),
  screen and (-ms-high-contrast: active) and (min-width: 1024px) {
    margin-top: 300px !important;
  }

  .work-grid__list {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.secondary};

    @media ${media.tabletLandscape} {
      ${grid8}
    }

    @media ${media.ieTabletLandscape} {
      max-width: 800px;
    }
  }

  .work-grid__item {
    @media ${media.ieTabletLandscape} {
      margin-bottom: 100px;
    }

    &--tall {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 4;
      }
    }

    &--double {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 8;
      }
    }

    &--hd {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 8;
      }
    }

    &--wide {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 8;
      }
    }

    &--standard {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 4;
        align-self: end;
        margin-bottom: 100px;
      }
    }

    &--square {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 4;
        align-self: end;
      }
    }

    + .work-grid__item {
      margin-top: 10px;

      @media ${media.tabletLandscape} {
        margin-top: 0;
      }
    }
  }

  .work-grid__nav {
    margin-top: 92px;
    margin-bottom: 92px;
    font-family: ${font.primary};
  }
`;
