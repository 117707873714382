import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledNextProjectWidget = styled.nav`
  ${themedMarginTop('67px')}
  ${themedMarginBottom('91px')}
  color: ${props => props.theme.secondary};

  @media ${media.tabletLandscape} {
    ${themedMarginTop('37px')}
    ${themedMarginBottom('94px')}
  }

  h3 {
    font-family: ${font.primaryBold};
    margin-top: 11px;
  }
`;
