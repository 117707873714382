import React from 'react';
import { Image } from '../parts/image';
import { WidgetFragment_Nmbl_TeamWidget_Fragment } from '../../../types/gatsby-types';
import { StyledTeamWidget } from './team.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { Heading3 } from '../../abstracts/typography.styles';
import { graphql } from 'gatsby';

export const TeamWidgetFragment = graphql`
  fragment TeamWidgetFragment on Nmbl_TeamWidget {
    title
    bag {
      contentItems {
        contentItemId
        contentType
        ... on Nmbl_TeamMember {
          name
          title
          imageWithRatio {
            image {
              ...ImageFragment
            }
            ratio
            alt
            caption
          }
          mobileImageWithRatio {
            image {
              ...ImageFragment
            }
            ratio
            alt
            caption
          }
        }
      }
    }
    theme {
      ...ThemeFragment
    }
  }
`;

export const TeamWidget: React.FC<WidgetFragment_Nmbl_TeamWidget_Fragment> = props => (
  <StyledWrapper as={StyledTeamWidget} className="team-widget">
    <StyledInViewElement className="team-widget__header">
      <h3 className="team-widget__title">{props.title}</h3>
    </StyledInViewElement>

    <ul className="team-widget__team-members">
      {props.bag?.contentItems?.map((teamMember, index) => {
        // only Team Members
        if (teamMember?.__typename === 'Nmbl_TeamMember') {
          const desktopImage = teamMember.imageWithRatio?.image?.src
            ? teamMember.imageWithRatio
            : teamMember.mobileImageWithRatio;
          const mobileImage = teamMember.mobileImageWithRatio?.image?.src
            ? teamMember.mobileImageWithRatio
            : teamMember.imageWithRatio;

          return (
            <li key={index} className="team-widget__team-member team-member">
              <StyledInViewElement>
                {mobileImage && mobileImage.ratio && (
                  <Image
                    className="team-member__image team-member__image--mobile"
                    image={mobileImage.image}
                    ratio={mobileImage.ratio}
                    alt={mobileImage.alt}
                    caption={mobileImage.caption}
                  />
                )}
                {desktopImage && desktopImage.ratio && (
                  <Image
                    className="team-member__image team-member__image--desktop"
                    image={desktopImage.image}
                    ratio={desktopImage.ratio}
                    alt={desktopImage.alt}
                    caption={desktopImage.caption}
                  />
                )}
              </StyledInViewElement>
              <StyledInViewElement>
                <Heading3>{teamMember.name}</Heading3>
                <h5>{teamMember.title}</h5>
              </StyledInViewElement>
            </li>
          );
        } else {
          return null;
        }
      })}
    </ul>
  </StyledWrapper>
);
