import React from 'react';
import { graphql } from 'gatsby';
import Img, { GatsbyImageProps } from 'gatsby-image';
import { ImageFragmentFragment } from '../../../types/gatsby-types';
import classNames from 'classnames';
import { isStringRatio } from '../../helpers/image-helpers';

// default uses aspect ratio of uploaded image
// 2:1 double 1320x660, 375x188, 315x157
// 4:3 standard 1440x1080, 630x472, 375x282, 315x236
// 8:5 wide 1440x900
// 16:9 hd 1440x810
// 1:1 square 315x315
// 3:4 tall 630x842, 375x500, 315x421
export const ImageFragment = graphql`
  fragment ImageFragment on Nmbl_MediaField {
    urls
    src {
      default: childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      double: childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      standard: childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      wide: childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      hd: childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 900, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      square: childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1600, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      tall: childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1600, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
// this is exactly the same as ImageFragment, but applies to `MetaJsonFallbackSeoImage`
// because the types are not correctly shared between Orchard GraphQL and Gatsby GraphQL
export const FallbackImageFragment = graphql`
  fragment FallbackImageFragment on MetaJsonFallbackSeoImage {
    urls
    src {
      default: childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      double: childImageSharp {
        fluid(maxWidth: 1320, maxHeight: 660) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      standard: childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      wide: childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      hd: childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 810) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      square: childImageSharp {
        fluid(maxWidth: 630, maxHeight: 630) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      tall: childImageSharp {
        fluid(maxWidth: 630, maxHeight: 840) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export interface IImageProps {
  className?: string;
  image: ImageFragmentFragment | undefined;
  ratio: string;
  caption?: string;
  paddingBottom?: number;
}

export type UsedGatsbyImageProps = Pick<
  GatsbyImageProps,
  'onStartLoad' | 'loading' | 'alt'
>;

export const Image: React.FC<IImageProps & UsedGatsbyImageProps> = ({
  className,
  image,
  ratio,
  caption,
  paddingBottom,
  ...gatsbyProps
}) => {
  if (!isStringRatio(ratio)) {
    throw new Error('Could not parse Image Ratio');
  }
  const src = image?.src && image.src[ratio];

  return (
    <figure className={classNames('image', className)}>
      {src?.fluid && (
        <div
          className="image-wrapper"
          style={
            paddingBottom
              ? {
                  paddingBottom: `${100 * paddingBottom}%`,
                }
              : undefined
          }
        >
          <Img fluid={src.fluid} {...gatsbyProps} />
        </div>
      )}
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};
