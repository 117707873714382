import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { WorkCard } from '../work/work-card';
import {
  selectWorkUrl,
  selectOrderedRatio,
  mobileOrderedRatios,
} from '../../helpers/work-selectors';
import { SiteMetaContext } from '../site-meta';
import {
  RecentWorkQuery,
  RecentWorkWidgetFragmentFragment,
} from '../../../types/gatsby-types';
import { StyledRecentWork } from './recent-work.styles';
import { StyledRecentWorkListItem } from './recent-work.styles';
import { StyledLink } from '../navigation/link.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { filterNotEmpty } from '../../helpers/arrayHelpers';

export const RecentWorkWidgetFragment = graphql`
  fragment RecentWorkWidgetFragment on Nmbl_RecentWorkWidget {
    work1 {
      contentItems {
        ... on Nmbl_Work {
          ...WorkCardFragment
        }
      }
    }
    work2 {
      contentItems {
        ... on Nmbl_Work {
          ...WorkCardFragment
        }
      }
    }
    work3 {
      contentItems {
        ... on Nmbl_Work {
          ...WorkCardFragment
        }
      }
    }
  }
`;

export const RecentWorkWidget: React.FC<RecentWorkWidgetFragmentFragment> = props => {
  const siteMeta = React.useContext(SiteMetaContext);
  const query = useStaticQuery<RecentWorkQuery>(graphql`
    query RecentWork {
      nmbl {
        page {
          contentItemId
          alias {
            alias
          }
        }
      }
    }
  `);
  const workPage = query.nmbl.page?.find(
    page => page?.contentItemId === siteMeta?.pageSettings?.workPageId
  );
  const work = React.useMemo(
    () =>
      [
        props.work1?.contentItems?.find(Boolean),
        props.work2?.contentItems?.find(Boolean),
        props.work3?.contentItems?.find(Boolean),
      ]
        .map(contentItem =>
          contentItem?.__typename === 'Nmbl_Work' ? contentItem : undefined
        )
        .filter(filterNotEmpty),
    [props.work1, props.work2, props.work3]
  );
  if (!workPage) {
    throw new Error('Could not find work page!');
  }
  return (
    <StyledRecentWork className="recent-work">
      <StyledWrapper as="ul" mobilePadding="0px" className="recent-work__list">
        {work.map((work, index) => (
          <StyledRecentWorkListItem key={work.contentItemId}>
            <WorkCard
              ratio={selectOrderedRatio(index)}
              mobileRatio={selectOrderedRatio(index, mobileOrderedRatios)}
              path={selectWorkUrl(
                `/${workPage.alias?.alias}`,
                work.alias?.alias
              )}
              {...work}
            />
          </StyledRecentWorkListItem>
        ))}
      </StyledWrapper>
      <StyledWrapper>
        <StyledLink
          className="recent-work__link"
          to={`/${workPage.alias?.alias}`}
        >
          View more
        </StyledLink>
      </StyledWrapper>
    </StyledRecentWork>
  );
};
