import { ImageFragmentFragment } from '../../types/gatsby-types';

// 2:1 double 1320x660, 375x188, 315x157
// 4:3 standard 1440x1080, 630x472, 375x282, 315x236
// 8:5 wide 1440x900
// 16:9 hd 1440x810
// 1:1 square 315x315
// 3:4 tall 630x842, 375x500, 315x421
export type Ratio = keyof Omit<NonNullable<ImageFragmentFragment['src']>, 'id'>;
export const aspectRatios: Record<Ratio, number | undefined> = {
  default: undefined,
  double: 2 / 1,
  standard: 4 / 3,
  wide: 8 / 5,
  hd: 16 / 9,
  square: 1 / 1,
  tall: 3 / 4,
};
export const isStringRatio = (value: string | undefined): value is Ratio =>
  !!value && Object.keys(aspectRatios).some(key => key === value);
