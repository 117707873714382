import React from 'react';
import {
  WorkFragmentFragment,
  PageFragmentFragment,
} from '../../../types/gatsby-types';

export type IWorkContext = {
  work: WorkFragmentFragment;
  workPage: PageFragmentFragment;
};

export const WorkContext = React.createContext<IWorkContext | null>(null);
