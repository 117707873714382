import React from 'react';
import { WidgetFragment_Nmbl_EditorWidget_Fragment } from '../../../types/gatsby-types';
import { graphql } from 'gatsby';
import { StyledCmsContent } from './editor.styles';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledInViewElement } from '../in-view/in-view-element.styles';

export const EditorWidgetFragment = graphql`
  fragment EditorWidgetFragment on Nmbl_EditorWidget {
    htmlBody {
      ...HtmlBodyFragment
    }
    theme {
      ...ThemeFragment
    }
  }
`;

export const EditorWidget: React.FC<WidgetFragment_Nmbl_EditorWidget_Fragment> = props => (
  <StyledWrapper className="editor-wrapper">
    <StyledInViewElement>
      <StyledCmsContent className="editor" html={props.htmlBody?.html} />
    </StyledInViewElement>
  </StyledWrapper>
);
